.mainArtProfile {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
}

.subMainArtProfile{
    width: 83%;
    display: flex;
    flex-direction: column;
    position: relative;
    left: 16.85vw;
    align-items: center;
    justify-content: flex-start;
}

.artDetails {
    width: 85%;
    margin-top: 7vw;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
}

.artDetails img {
    width: 40%;
    height: 20vw;
    object-fit: cover;
}

.artDetailsText {
    height: 20vw;
    position: relative;
    left: 3vw;
}

p.artName {
    font-size: 2vw;
    margin: 0;
    font-family: 'Manrope',sans-serif;
    font-weight: 600;
}

.artArtist span {
    font-size: 0.8vw;
    margin-right: 0.2vw;
}

.artArtist {
    font-size: 1.2vw;
    font-family: 'Manrope', sans-serif;
    font-weight: 400;
}

.artPrice {
    font-size: 1.5vw;
    font-family: 'Manrope',sans-serif;
    font-weight: 500;
}

.artDetailsText button {
    border: none;
    background: black;
    color: white;
    padding: 0.8vw 2vw;
    font-size: 0.8vw;
    border-radius: 3vw;
    position: relative;
    top: 7vw;
}

.extraDetails {
    margin-bottom: 7vw;
    margin-top: 7vw;
    width: 85%;
    display: grid;
    grid-template-columns: 45% 45%;
    grid-row-gap: 2vw;
}

.detailInputs p {
    font-size: 1vw;
    font-family: 'Manrope', sans-serif;
    font-weight: 500;
}

.detailInputs input {
    width: 80%;
    border: thin solid #d7d5d5;
    padding: 1vw;
    font-size: 0.8vw;
    border-radius: 3vw;
    font-family: 'Manrope',sans-serif;
}

