.mainArtUpload{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items:center;
    justify-content: flex-start;
    max-height: 4vw;
    transition: all .5s ease-in;
    overflow: hidden;;
}

#remove{
    display: none !important;
}

.openForm{
    max-height: 100vw !important;
}

.artUploadFormHeader {
    width: 92%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background: #F6F6F6;
    padding: 0 1.5vw;
    border-radius: 0.5vw;
}

.formStateContainer {
    width: 8%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    font-size: 0.8vw;
    font-weight: 500;
    font-family: 'Manrope',sans-serif;
}

.formStateContainer img{
    width: 10%;
    transition: all .2s ease-in;
}

.turn {
    transform: rotate(180deg);
}

.cancel{
    font-size: 0.8vw;
    cursor: pointer;
    font-weight: 600;
    color: black;
}

.successFul h1 {
    font-size: 1.5vw;
    font-family: 'Manrope',sans-serif;
}
