.mainNotifications{
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
}

.NotificationSubMain {
    width: 83%;
    display: flex;
    flex-direction: column;
    position: relative;
    left: 16.85vw;
}

.notificationHeader {
    width: 77%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #F6F6F6;
    padding: 0 3vw 0 3vw;
    position: fixed;
    z-index: 1;
    left: 17vw;
}

.notificationHeader p {
    font-size: 1vw;
    font-family: 'Manrope', sans-serif;
    font-weight: 700;
}

.notificationsGrid{
    width: 60%;
    position: relative;
    top: 10vw;
    left: 16vw;
}

.notificationCards {
    width: 80%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    position: relative;
    top: 7vw;
    margin-bottom: 6vw;
    left: 7vw;
}

.notCard {
    width: 40%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    color: white;
    background: black;
    cursor: pointer;
    font-size: 1.2vw;
    padding: 1vw;
    border-radius: 0.5vw;
}