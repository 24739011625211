.mainArtistProfiles {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
}

.ArtistProfilesSubMain {
    width: 83%;
    display: flex;
    flex-direction: column;
    position: relative;
    left: 16.85vw;
    align-items: center;
    justify-content: flex-start;
}

.ArtistProfilesHeader {
    width: 77%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #F6F6F6;
    padding: 0 3vw 0 3vw;
    position: fixed;
    z-index: 1;
    left: 17vw;
}

.ArtistProfilesHeader p {
    font-size: 1vw;
    font-family: 'Manrope', sans-serif;
    font-weight: 700;
}


.ArtistProfileFloW {
    position: relative;
    top: 4vw;
    align-self: flex-start;
    left: 3vw;
    font-family: 'Manrope',sans-serif;
}

.ArtistProfileFloW p {
    font-size: 0.8vw;
    font-weight: 500;
}

.ArtistProfileFloW p span{
    color: #848383;
}

.ArtistProfileForm {
    width: 93%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    position: relative;
    top: 5vw;
}

.Form1, .Form2 {
    width: 45%;
    display: flex;
    font-family: 'Manrope', sans-serif;
    flex-direction: column;
    align-items: flex-start;
}

.input1 {
    width: 80%;
    margin-top: 1vw;
}

.input1 input {
    border: thin solid #E2E8F0;
    outline: none;
    width: 100%;
    padding: 1vw 2vw 1vw 2vw;
    border-radius: 2vw;
    font-size: 0.9vw;
}

.input1 p {
    font-size: 0.9vw;
}

.input1 select{
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border: thin solid #E2E8F0;
    outline: none;
    width: 113%;
    padding: 1vw 2vw 1vw 2vw;
    border-radius: 2vw;
    font-size: 0.9vw;
}

.Form2 input[type=file]::file-selector-button {
    background: black;
    color: white;
    padding: 0.7vw 2vw 0.7vw 2vw;
    font-size: 1vw;
    border-radius: 3vw;
    border: none;
}

input[type=file]{ 
    color:transparent;
}

.ArtistProfileForm .input2 {
    width: 80%;
    margin-top: 1vw;
    position: relative;
    top: 12.4vw;
}

.input2 input {
    border: thin solid #E2E8F0;
    outline: none;
    width: 100%;
    padding: 1vw 2vw 1vw 2vw;
    border-radius: 2vw;
    font-size: 0.9vw;
}

.input2 p {
    font-size: 0.9vw;
}

.ArtistProfileForm .Form2 {
    position: relative;
    bottom: 6vw;
}

.Form2 img {
    width: 60%;
}

.form2Uploads button {
    background: white;
    color: black;
    border: thin solid black;
    padding: 0.7vw 1vw 0.7vw 1vw;
    font-size: 1vw;
    border-radius: 3vw;
}

.form2Uploads {
    position: relative;
    top: 2vw;
    right: 1vw;
}

.artistProfileButtons button{
    width: 45%;
    border: thin solid black;
    border-radius: 3vw;
    background: white;
    color: black;
    padding: 0.7vw 2vw 0.7vw 2vw;
    font-size: 1vw;
}

.artistProfileButtons .formSubmit{
    background: black;
    color: white;
    padding: 0.7vw 2vw 0.7vw 2vw;
    font-size: 1vw;
    border-radius: 3vw;
    border: none;
    width: 100%;
    position: relative;
    top: 0vw;
    margin-bottom: 0vw;
}

.artistProfileButtons div{
    width: 45%;
}

.artistProfileButtons {
    width: 60%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    position: relative;
    top: 8vw;
    margin-bottom: 10vw;
    margin-top: 6vw;
}

.loading{
    background-image: url(./loader.gif);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    color: black !important;
}

.deleteLoading{
    background-image: url(./white_loader.gif) !important;
    background-size: contain !important;
    background-repeat: no-repeat !important;
    background-position: center !important;
    color: white !important;
}

.works {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}

.worksHeader {
    width: 90%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    position: relative;
    right: 3vw;
}

.worksHeaderTexts{
    width: 30%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    position: relative;
    left: 4vw;
}

.worksHeaderTexts p{
    font-size: 1vw;
    font-weight: 600;
    font-family: 'Manrope', sans-serif;
}

.css-b62m3t-container {
    width: 26%;
    position: relative;
    right: 6vw;
    font-size: 0.8vw;
    font-family: 'Manrope';
    color: grey;
}

.css-t3ipsp-control{
    border-color: transparent !important;
    box-shadow: none !important;
}

.css-t3ipsp-control:hover{
    border: none !important;
    box-shadow: none !important;
}

.css-13cymwt-control{
    border-color: transparent !important;
}

.worksHeader button {
    background: black;
    color: white;
    border: none;
    font-size: 0.8vw;
    font-family: 'Manrope';
    padding: 0.7vw 2vw;
    border-radius: 3vw;
}

.image404 {
    width: 20%;
}

.workSlides{
    width: 80%;
    position: relative;
    left: 2vw;
    top: 3vw;
}


.keen-slider__slide p {
    font-size: 0.9vw;
    margin-top: 0.6vw;
    margin-bottom: 0;
    font-weight: 600;
    font-family: 'Manrope', sans-serif;
    cursor: pointer;
}

.artprice {
    padding: 0vw 0 0 0;
    width: 80%;
    position: relative;
    top: 1vw;
    border-top: thin solid #c1b6b6;
}

button.buttonBack___1mlaL.carousel__back-button {
    background: transparent;
    border: thin solid #a6a3a3;
    border-radius: 0.2vw;
    font-size: 1.5vw;
    height: 2vw;
    text-align: center;
    position: absolute;
    display: flex;
    bottom: 4vw;
    left: 29vw;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-family: 'Manrope', sans-serif;
}

button.buttonNext___2mOCa.carousel__next-button{
    background: transparent;
    border: thin solid #a6a3a3;
    border-radius: 0.2vw;
    font-size: 1.5vw;
    height: 2vw;
    text-align: center;
    position: absolute;
    display: flex;
    bottom: 4vw;
    left: 33vw;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-family: 'Manrope', sans-serif;
}