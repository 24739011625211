.mainArts {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
}

.ArtsSubMain {
    width: 83%;
    display: flex;
    flex-direction: column;
    position: relative;
    left: 16.85vw;
    align-items: center;
    justify-content: flex-start;
}

.ArtsHeader {
    width: 77%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #F6F6F6;
    padding: 0 3vw 0 3vw;
    position: fixed;
    z-index: 1;
    left: 17vw;
}

.ArtsHeader p {
    font-size: 1vw;
    font-family: 'Manrope', sans-serif;
    font-weight: 700;
}

.ArtsCards {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    position: relative;
    top: 8vw;
}

.value {
    font-weight: 700;
    font-size: 1.5vw;
    font-family: 'Manrope', sans-serif;
}

.item {
    color: #868686;
    font-weight: 400;
    font-family: 'Manrope', sans-serif;
    font-size: 1vw;
}

.card {
    width: 20%;
    box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0.05);
    padding: 1vw 3vw 1vw 3vw;
    border-radius: 0.2vw;
}

.ArtsListing{
    width: 77%;
    position: relative;
    top: 8vw;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.ArtsListingHeader{
    width: 40%;
    position: relative;
    top: 8vw;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.ArtsListingHeader .css-b62m3t-container{
    width: 45%;
}

.ArtsListingHeader p {
    font-size: 1vw;
    font-weight: 600;
    font-family: 'Manrope',sans-serif;
}

.ArtsListingHeader button {
    border: none;
    background: black;
    color: white;
    font-size: 1vw;
    padding: 0.7vw 2vw 0.7vw 2vw;
    border-radius: 3vw;
}

.ArtsSubMain .ArtGrid {
    width: 80%;
    position: relative;  
    left: 0vw;
    top: 18vw;
}

.css-z8fhq1-MuiDataGrid-columnHeaders{
    background: black;
    color: white;
}

.css-ptiqhd-MuiSvgIcon-root{
    fill: white !important;
}

.addExhiButton{
    border: none;
    padding: 1vw 2vw;
    font-size: 1vw;
    font-family: 'Manrope',sans-serif;
    font-weight: 600;
    color: white;
    background: black;
    position: relative;
    top: 8vw;
    border-radius: 3vw;
}