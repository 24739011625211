.Sidebar {
    background: black;
    height: 100vh;
    width: 17%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: fixed;
    z-index: 1;
}

.sidebarCreath {
    width: 30%;
}

.Menu_Items {
    width: 100%;
}

.Item {
    background: black;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 0 0 0 0vw;
    cursor: pointer;
    margin: 2vw 0 0 0;
}

.Item p{
    color: white;
    font-family: 'Manrope', sans-serif;
    font-size: 1vw;
    position: relative;
    left: 5vw;
}

.Item img{
    position: relative;
    left: 3vw;
}

.hovering {
    background: white;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 0 0 0 0vw;
}

.hovering p{
    color: black;
    font-family: 'Manrope', sans-serif;
    font-size: 1vw;
    position: relative;
    left: 5vw;
}

#selected {
    background: white;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 0 0 0 0vw;
}

#selected p{
    color: black;
    font-family: 'Manrope', sans-serif;
    font-size: 1vw;
    position: relative;
    left: 5vw;
}