.mainAddArt1{
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
}

.subMainAddArt1{
    width: 83%;
    display: flex;
    flex-direction: column;
    position: relative;
    left: 16.85vw;
    align-items: center;
    justify-content: flex-start;
}

.formHeaderButtons {
    position: relative;
    margin-top: 5vw;
    width: 20%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.formHeaderButtons button {
    border: none;
    background: transparent;
    color: grey;
    font-family: 'Manrope', sans-serif;
    font-size: 0.8vw;
    width: 40%;
    padding: 0 1vw 0.5vw 1vw;
    cursor: pointer;
}

.formHeaderButtons button:hover{
    color: black;
}

.currentType{
    border-bottom: 0.15vw solid black !important;
    color: black !important;
}

.addArtForms {
    width: 80%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 5vw;
}

.addSingleForms {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    position: relative;
    left: 2vw;
    margin-bottom: 8vw;
}

.addSingleForm1 .input p {
    font-size: 0.8vw;
    font-family: 'Manrope',sans-serif;
    color: black;
}

.addSingleForm1 {
    width: 40%;
}

.addSingleForm1 .input {
    width: 100%;
    margin-top: 2vw;
}

.addSingleForm1 .input input {
    outline: none;
    border: thin solid #a3a0a0;
    padding: 1vw;
    border-radius: 3vw;
    font-size: 0.8vw;
    font-family: 'Manrope', sans-serif;
    width: 90%;
}

.addSingleForm1 .input .css-b62m3t-container {
    width: 99%;
    right: 0;
}

.addSingleForm1 .input .css-13cymwt-control {
    border-color: #bfbcbc !important;
    padding: 0.7vw;
    border-radius: 3vw;
}

.addSingleForm1 .input .css-t3ipsp-control{
    border-color: #bfbcbc !important;
    padding: 0.7vw !important;
    border-radius: 3vw !important;
    box-shadow: none !important;
}

.addSingleForm1 .input .css-t3ipsp-control:hover{
    border-color: #bfbcbc !important;
    border: thin solid #bfbcbc !important;
    padding: 0.7vw !important;
    border-radius: 3vw !important;
    box-shadow: none !important;
}

.addSingleForm1 .input .css-13cymwt-control{
    border-color: #bfbcbc !important;
    padding: 0.7vw !important;
    border-radius: 3vw !important;
}

.addSingleForm2 input[type=file]::file-selector-button {
    background: white;
    color: black;
    padding: 0.7vw 2vw 0.7vw 2vw;
    font-size: 1vw;
    border-radius: 3vw;
    border: thin solid grey;
    margin-top: 3vw;
    position: relative;
    left: 2vw;
}

.addSingleForm2 {
    display: flex;
    width: 50%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 2vw;
}

.artMedia{
    width: 80%;
    height: 20vw;
}

.addSingleForms button {
    position: absolute;
    bottom: -5vw;
    background: black;
    color: white;
    border: none;
    font-family: 'Manrope',sans-serif;
    font-size: 0.8vw;
    padding: 0.8vw 8vw;
    border-radius: 3vw;
}

.addCollectionFoms {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    position: relative;
    left: 2vw;
    margin-bottom: 8vw;
}

.addCollectionForm1 .input p {
    font-size: 0.8vw;
    font-family: 'Manrope',sans-serif;
    color: black;
}

.addCollectionForm1 {
    width: 40%;
}

.addCollectionForm1 .input {
    width: 100%;
    margin-top: 2vw;
}

.addCollectionForm1 .input input {
    outline: none;
    border: thin solid #a3a0a0;
    padding: 1vw;
    border-radius: 3vw;
    font-size: 0.8vw;
    font-family: 'Manrope', sans-serif;
    width: 90%;
}

.addCollectionForm2 input[type=file]::file-selector-button {
    background: white;
    color: black;
    padding: 0.7vw 2vw 0.7vw 2vw;
    font-size: 1vw;
    border-radius: 3vw;
    border: thin solid grey;
    margin-top: 3vw;
    position: relative;
    left: 2vw;
}

.addCollectionForm2 {
    display: flex;
    width: 50%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 2vw;
}

.artMedia{
    width: 80%;
    height: 20vw;
}

.file_label{
    color: black;
    font-size: 0.8vw;
    font-family: 'Manrope',sans-serif;
    font-weight: 600;
}

.addCollectionFoms button {
    position: absolute;
    bottom: -6vw;
    background: black;
    color: white;
    border: none;
    font-family: 'Manrope',sans-serif;
    font-size: 0.8vw;
    padding: 0.8vw 11vw;
    border-radius: 3vw;
    cursor: pointer;
}

.loading{
    background-image: url(./loader.gif) !important;
    background-size: contain !important;
    background-repeat: no-repeat !important;
    background-position: center !important;
    color: black !important;
}
