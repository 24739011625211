.mainAddExhibtion{
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
}

.subAddExhibition{
    width: 83%;
    display: flex;
    flex-direction: column;
    position: relative;
    left: 16.85vw;
    align-items: center;
    justify-content: flex-start;
}

.subAddExhibition .addCollectionFoms{
    margin-top: 5vw;
    left: 4vw;
}

.subAddExhibition .input .css-b62m3t-container {
    width: 99%;
    right: 0;
}

.subAddExhibition .input .css-13cymwt-control {
    border-color: #bfbcbc !important;
    padding: 0.7vw;
    border-radius: 3vw;
}

.subAddExhibition .input .css-t3ipsp-control{
    border-color: #bfbcbc !important;
    padding: 0.7vw !important;
    border-radius: 3vw !important;
    box-shadow: none !important;
}

.subAddExhibition .input .css-t3ipsp-control:hover{
    border-color: #bfbcbc !important;
    border: thin solid #bfbcbc !important;
    padding: 0.7vw !important;
    border-radius: 3vw !important;
    box-shadow: none !important;
}

.subAddExhibition .input .css-13cymwt-control{
    border-color: #bfbcbc !important;
    padding: 0.7vw !important;
    border-radius: 3vw !important;
}

.subAddExhibition .addCollectionFoms{
    width: 90%;
}

#file_label{
    text-align: center;
}

.mainAddExhibtion .addCollectionFoms button{
    bottom: -6vw;
}