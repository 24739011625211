.addArtist2FormHeader {
    margin-top: 5vw;
}

.addArtist2FormHeader p{
    font-family: 'Manrope', sans-serif;
    font-size: 1vw;
    font-weight: 600;
    border-bottom: thin solid black;
    padding: 0vw 1vw 0.5vw 1vw
}

.uploadForms{
    width: 87%;
}

.addMoreButton{
    position: fixed;
    bottom: 5vw;
    right: 5vw;
    background: black;
    color: white;
    border: none;
    font-size: 0.8vw;
    padding: 0.8vw 2vw 0.8vw 2vw;
    font-family: 'Manrope',sans-serif;
    font-weight: 700;
    border-radius: 3vw;
}

.closeButton{
    position: fixed;
    bottom: 5vw;
    right: 15vw;
    background: black;
    color: white;
    border: none;
    font-size: 0.8vw;
    padding: 0.8vw 2vw 0.8vw 2vw;
    font-family: 'Manrope',sans-serif;
    font-weight: 700;
    border-radius: 3vw;
}