.loginMain {
    width: 45%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-family: 'Manrope', sans-serif;
    background: #FAFAFA;
    position: absolute;
    margin: auto;
    left: 0;
    right: 0;
    top: 6vw;
    padding: 4vw 0 4vw 0;
}

.loginMain h1 {
    font-size: 1.8vw;
    font-weight: 500;
    margin: 0;
}

.loginMain p {
    font-size: 1vw;
    font-weight: 300;
}

.loginForm {
    width: 80%;
}

.loginMain input {
    width: 95%;
    padding: 0.8vw 0 0.8vw 2vw;
    outline: none;
    border: thin solid #E2E8F0;
    border-radius: 3vw;
    font-family: 'Manrope',sans-serif;
    font-size: 1vw;
}

.loginForm button {
    width: 100%;
    border: none;
    font-family: 'Manrope',sans-serif;
    color: white;
    background: black;
    font-size: 1vw;
    padding: 0.8vw;
    border-radius: 3vw;
    margin-top: 3vw;
}

.loadingLogin{
    background-image: url(./loader.gif) !important;
    background-size: contain !important;
    background-repeat: no-repeat !important;
    background-position: center !important;
    color: black !important;
}