.mainAddAdmins {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
}

.AddAdminsSubMain {
    width: 83%;
    display: flex;
    flex-direction: column;
    position: relative;
    left: 16.85vw;
    align-items: center;
    justify-content: flex-start;
}

.AddAdminsHeader {
    width: 77%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #F6F6F6;
    padding: 0 3vw 0 3vw;
    position: fixed;
    z-index: 1;
    left: 17vw;
}

.AddAdminsHeader p {
    font-size: 1vw;
    font-family: 'Manrope', sans-serif;
    font-weight: 700;
}


.AddAdminFloW {
    position: relative;
    top: 4vw;
    align-self: flex-start;
    left: 3vw;
    font-family: 'Manrope',sans-serif;
}

.AddAdminFloW p {
    font-size: 0.8vw;
    font-weight: 500;
}

.AddAdminFloW p span{
    color: #848383;
}

.AddAdminForm {
    width: 93%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    position: relative;
    top: 5vw;
}

.Form1, .Form2 {
    width: 45%;
    display: flex;
    font-family: 'Manrope', sans-serif;
    flex-direction: column;
    align-items: flex-start;
}

.input1 {
    width: 80%;
    margin-top: 1vw;
}

.input1 input {
    border: thin solid #E2E8F0;
    outline: none;
    width: 100%;
    padding: 1vw 2vw 1vw 2vw;
    border-radius: 2vw;
    font-size: 0.9vw;
}

.input1 p {
    font-size: 0.9vw;
}

.input1 select{
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border: thin solid #E2E8F0;
    outline: none;
    width: 113%;
    padding: 1vw 2vw 1vw 2vw;
    border-radius: 2vw;
    font-size: 0.9vw;
}

.Form2 input[type=file]::file-selector-button {
    background: black;
    color: white;
    padding: 0.7vw 2vw 0.7vw 2vw;
    font-size: 1vw;
    border-radius: 3vw;
    border: none;
}

input[type=file]{ 
    color:transparent;
}

.input2 {
    width: 80%;
    margin-top: 1vw;
    position: relative;
    top: 12.5vw;
}

.input2 input {
    border: thin solid #E2E8F0;
    outline: none;
    width: 100%;
    padding: 1vw 2vw 1vw 2vw;
    border-radius: 2vw;
    font-size: 0.9vw;
}

.input2 p {
    font-size: 0.9vw;
}

.mainAddAdmins .Form2 {
    position: relative;
    bottom: 0vw;
}

.Form2 img {
    width: 60%;
}

.form2Uploads button {
    background: white;
    color: black;
    border: thin solid black;
    padding: 0.7vw 1vw 0.7vw 1vw;
    font-size: 1vw;
    border-radius: 3vw;
}

.form2Uploads {
    position: relative;
    top: 2vw;
    right: 1vw;
}

.mainAddAdmins .formSubmit{
    background: black;
    color: white;
    padding: 0.7vw 2vw 0.7vw 2vw;
    font-size: 1vw;
    border-radius: 3vw;
    border: none;
    width: 50%;
    position: relative;
    top: 20vw;
    margin-bottom: 10vw;
}

.mainAddAdmins .loading{
    background-image: url(./loader.gif) !important;
    background-size: contain !important;
    background-repeat: no-repeat !important;
    background-position: center !important;
    color: black !important;
}
