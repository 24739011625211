.mainAdmins {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
}

.AdminsSubMain {
    width: 83%;
    display: flex;
    flex-direction: column;
    position: relative;
    left: 16.85vw;
    align-items: center;
    justify-content: flex-start;
}

.AdminsHeader {
    width: 77%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #F6F6F6;
    padding: 0 3vw 0 3vw;
    position: fixed;
    z-index: 1;
    left: 17vw;
}

.AdminsHeader button{
    background-color: black;
    border: none;
    padding: 0.5vw 2vw 0.5vw 2vw;
    color: white;
    border-radius: 3vw;
    font-size: 0.8vw;
    font-family: 'Manrope', sans-serif;
}

.AdminsHeader p {
    font-size: 1vw;
    font-family: 'Manrope', sans-serif;
    font-weight: 700;
}

.AdminsSubMain .adminGrid {
    width: 70%;
    position: relative;
    left: 0vw;
    top: 6vw;
}
