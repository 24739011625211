@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600;700;800&family=Playfair+Display:ital,wght@0,400;0,500;0,600;0,700;0,800;0,900;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
.workSlides img {
    width: 90%;
    height: 19vw;
    object-fit: contain;
}
button.buttonNext___2mOCa.carousel__next-button{
    bottom: -1vw !important;
}

button.buttonBack___1mlaL.carousel__back-button{
    bottom: -1vw !important;
}