.exhibitionsubMain{
    width: 83%;
    display: flex;
    flex-direction: column;
    position: relative;
    left: 16.85vw;
    align-items: center;
    justify-content: flex-start;
}

.exhibitionDetails {
    width: 80%;
    margin-top: 8vw;
}

.exhibitionDetails p {
    font-size: 1.2vw;
    font-weight: 500;
    font-family: 'Manrope', sans-serif;
}

.extraCollections {
    margin-top: 5vw;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    width: 80%;
    margin-bottom: 3vw;
}

.extraCollections p{
    font-size: 2vw;
    font-weight: 600;
    font-family: 'Manrope', sans-serif;
}

.exhibitionDetails button {
    border: none;
    background: black;
    color: white;
    padding: 0.8vw 3vw;
    font-size: 1vw;
    border-radius: 3vw;
}

.imageUpld{
    border: none;
    background: black;
    color: white;
    padding: 0.8vw 3vw;
    font-size: 1vw;
    border-radius: 3vw;
    align-self: flex-start;
    position: relative;
    margin-top: 3vw;
    left: 8vw;
}

.exhibitionsubMain .addCollectionForm2 {
    align-self: flex-start;
}