.mainDash {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
}

.dashSubMain {
    width: 83%;
    display: flex;
    flex-direction: column;
    position: relative;
    left: 16.85vw;
}

.dashHeader {
    width: 77%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #F6F6F6;
    padding: 0 3vw 0 3vw;
    position: fixed;
    z-index: 1;
    left: 17vw;
}

.dashHeader p {
    font-size: 1vw;
    font-family: 'Manrope', sans-serif;
    font-weight: 700;
}

.dashCards {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    position: relative;
    top: 8vw;
}

.value {
    font-weight: 700;
    font-size: 1.5vw;
    font-family: 'Manrope', sans-serif;
}

.item {
    color: #868686;
    font-weight: 400;
    font-family: 'Manrope', sans-serif;
    font-size: 1vw;
}

.card {
    width: 20%;
    box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0.05);
    padding: 1vw 3vw 1vw 3vw;
    border-radius: 0.2vw;
}

.artistGrid {
    width: 80%;
    position: relative;
    left: 8.4vw;
    top: 14vw;
}

.dashArtistListingText{
    font-family: 'Manrope', sans-serif;
    font-weight: 700;
    position: relative;
    font-size: 1vw;
    top: 12vw;
    left: 8.5vw;
    width: 10%;
}

.css-ptiqhd-MuiSvgIcon-root{
    fill: white !important;
}