.mainCollectionProfile {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
}

.subMainCollectionProfile{
    width: 83%;
    display: flex;
    flex-direction: column;
    position: relative;
    left: 16.85vw;
    align-items: center;
    justify-content: flex-start;
}

.collectionTextDetails {
    width: 80%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-top: 5vw;
}

.deet p {
    font-size: 1.2vw;
    font-family: 'Manrope',sans-serif;
    font-weight: 500;
}

.collectionTextDetails img{
    width: 35%;
    height: 20vw;
    object-fit: cover;
}

.slideHeader{
    font-size: 2vw;
    font-weight: 600;
    font-family: 'Manrope', sans-serif;
}

#closeSlide{
    display: none;
}

.textDetails button {
    background: black;
    color: white;
    border: none;
    padding: 1vw 1.2vw;
    font-size: 1vw;
    border-radius: 3vw;
    position: relative;
    top: 4vw;
}

button.addtoCollect {
    border-radius: 3vw;
    background: black;
    color: white;
    font-family: 'Manrope', sans-serif;
    border: none;
    padding: 1vw 3vw;
    font-size: 1.2vw;
    margin-top: 6vw;
    position: relative;
    left: 1vw;
}