.mainCollectionCard {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    border: thin solid rgb(216, 216, 216);
    border-radius: 1vw;
    padding: 2vw;
    margin-bottom: 2vw;
}

.details img {
    width: 40%;
    height: 11vw;
    object-fit: cover;
}

.details {
    width: 50%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;

}

.textDeets{
    height: 11vw;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-left: 2vw;
    justify-content: center;
}

.textDeets .desc {
    font-size: 1vw;
    font-weight: 300;
    margin: 0;
    overflow: hidden;
}

.textDeets .name {
    font-size: 1.2vw;
    margin: 0;
}

.mainCollectionCard button{
    background: black;
    border: none;
    color: white;
    padding: 0.8vw 1.5vw;
    font-size: 0.8vw;
    border-radius: 0.3vw;
}

#exclude{
    display: none !important;
}